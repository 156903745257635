$mainColor: rgb(57, 202, 212);

$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content;
    }
}



$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Large tablets and desktops
$screen-xxl-min: 1900px;



// Small devices
@mixin sm {
   @media (max-width: #{$screen-sm-min}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (min-width: #{$screen-md-min}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (min-width: #{$screen-lg-min}) {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media (min-width: #{$screen-xl-min}) {
       @content;
   }
}

// Extra large devices
@mixin xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}