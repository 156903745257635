@import "../../global.scss";

.portfolio {
background-color: rgb(255, 255, 255);
 background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(0, 0, 0);
  overflow: hidden;

  img {
    height: 35vh;
  }

  h1 {
    font-size: 60px;
    color: $mainColor;
    padding: 40px;
    overflow: hidden;
    color: black;
    
    font-family: 'Concert One', cursive;
    font-family: 'Graduate', cursive;

    
    @include mobile{
      font-size: 25px;
    }
 
    @include sm {
      font-size: 25px;
    }  

}

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 5px 0;
      flex-wrap: wrap;
      justify-content: center;  
    }

  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;  
        
  

    .item {    
      width: 220px;
      height: 150px; 
      border-radius: 20px;
      border: 2px solid rgb(0, 0, 0);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(0, 0, 0);
      position: relative;
      transition: all .5s ease;
      cursor: pointer;
     

      @include xl {
        width: 220px;
        height: 130px;    
      }

      @include xxl {
        width: 320px;
        height: 230px;
      }

      @include mobile{
        width: 130px;
        height: 80px;
        overflow: hidden;      
      }

      h3 {
        position: absolute;
        font-size: 30px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}
