@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;
    

  @include mobile {
    flex-direction: column;
    
  }

 .contactIcons {
    display: flex;
    justify-content: center;
    cursor: pointer;
  
  }

.contactIcons div {
    padding: 10px;
    overflow: hidden;
 }

  .left {
   
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      height: 60%;
  }
  
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   

    h2 {
      font-size: 30px;  
      overflow: hidden;
    }

    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;



      input {
        width: 300px;
        height: 30px;
        font-size: 18px;

        @include mobile {
          width: 200px;
          margin: 10px;
          height: 30px;
        }
        
      }

      textarea {
        width: 330px;
        height: 300px;
        font-size: 18px;

        @include mobile {
          width: 230px;
          margin: 10px;
          height: 200px;
        }
      }

      button {
        width: 150px;
        height: 50px;
        color: white;
        background-color: crimson;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
        margin: 20px;
        

        &:focus {
          outline: none;
        }
      }

      span {
        color: green;
        
      }
    }
  }
}
