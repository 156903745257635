@import "../../global.scss";



.intro {
  
  background-color: rgb(0, 0, 0);
  display: flex;

  @include mobile{
    flex-direction: column;
    align-items: center;
    
  }

  .left {
    flex: 0.5;
    overflow: hidden;

    .imgContainer {
      
      width: 700px;
      height: 700px;  
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;

      @include mobile{
        align-items: flex-start;
        height: 200%;         
      }

      img {
        height: 95%;  

        @include mobile{
          height: 60%;
        }

      }

    }
  };


  .right {
    flex: 0.5;
    position: relative;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile{
        padding-left: 0;
        align-items: center;      
        height: 60%;
      }

      h1 {
        font-size: 60px;
        margin: 10px 0;
        color: $mainColor;

        font-weight: 900;
        font-size: calc(2rem + 1.5vw); 
        font-family: 'Merienda', cursive;

        @include mobile{
          font-size: 30px;
        }
      }
      h2 {
        font-size: 35px;
        color: blanchedalmond;
        @include mobile{
          font-size: 20px;
        }
      }
      h3 {
        font-size: 30px;
        color: blanchedalmond;

        @include mobile{
          font-size: 15px;
        }

        span {
          font-size: inherit;
          color: rgb(255, 166, 0);
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          0% {
            opacity: 0;
          }
          50% {
            opacity: .5;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
    a {
      position: absolute;
      bottom: 30px;
      left: 15%;

      @include mobile{
        left: 40%;;
      }

  

      .arrows {
        width: 80px;
        color: $mainColor;
        animation: arrowBlink 1.5s infinite;
        
        @include mobile{
          width: 50px;
        }
  
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
